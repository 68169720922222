import React from "react";
import {
  FaWhatsapp,
  FaInstagram,
  FaLinkedinIn,
  FaTelegram,
  FaTwitter,
} from "react-icons/fa6";
import ioscBVP from "./images/ioscBVP.jpg";
import { LocationOnRounded } from "@mui/icons-material";
// import logo from "./images/IOSC LOGO (1).png"

const Footer = () => {
  return (
    <footer>
      <div className="footer-content">
        <div className="ft-logo ft-box">
          <img src={ioscBVP} alt="Logo" style={{ borderRadius: "50%" }} />
          <p className="pow-by">Organised by IOSC BVP</p>
        </div>
        <div className="social ft-box">
          <h2 className="footer-title">Connect With Us</h2>
          <div className="social-links">
            <a
              href="https://www.instagram.com/iosc.bvcoe/"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram className="social-icon" />
            </a>

            {/* <a href="https://t.me/phicsitofficial" target="_blank" rel="noreferrer">
              <FaTelegram className="social-icon" />
            </a> */}
            <a href="" target="_blank" rel="noreferrer">
              <FaWhatsapp className="social-icon" />
            </a>

            <a
              href="https://www.linkedin.com/company/iosc-bvcoe/"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedinIn className="social-icon" />
            </a>
            {/* <a href="https://twitter.com/PHICSIT" target="_blank" rel="noreferrer">
              <FaTwitter className="social-icon" />
            </a> */}
          </div>
        </div>
        <div className="contact-us ft-box">
          <h2 className="footer-title">Contact Us</h2>
          <p className="contact-us-email">
            <a
              href="mailto:techgenesis@ioscbvp.tech"
              target="_blank"
              rel="noreferrer"
            >
              techgenesis@ioscbvp.tech
            </a>
          </p>
          <br />
          <div className="location">
            <p
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              <LocationOnRounded style={{ marginRight: "10px" }} />
              Bharati Vidyapeeth's College of Engineering
            </p>
            <p style={{marginLeft:"34px"}}>
              A-4 Block,
              <br />
              Baba Ramdev Marg,
              <br />
              Shiva Enclave,
              <br />
              Paschim Vihar,
              <br />
              New Delhi, Delhi, 110063
            </p>
          </div>
        </div>
      </div>
      <div className="footer-end">
        <p className="copyright">Made by Web Development Team IOSC-BVP</p>
      </div>
    </footer>
  );
};

export default Footer;
