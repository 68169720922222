import React from "react";

const About = () => {
  return (
    <>
      <section className="section" id="about">
        <div className="about-section">
          <h2 className="common-title">About Us</h2>
          <div className="content">
            <p style={{textAlign:"justify"}}>
              <b style={{ color:"darkgoldenrod",fontSize:"25px" }}>TechGenesis</b> is not just
              another tech fair – it's a dynamic immersion into innovation and
              creativity. Featuring{" "}
              <b style={{ color:"darkgoldenrod",fontSize:"20px" }}>“Hackathon DEV.FUSION”</b> where
              brilliant minds code groundbreaking solutions, and{" "}
              <b style={{ color:"darkgoldenrod",fontSize:"20px" }}>“Exhibition PROJEXON”</b> that
              highlights the fusion of technology and imagination. Join us for a
              cutting- edge experience with mind-bending competitions and
              technical extravaganzas, igniting your tech enthusiasm!
            </p>
            <p style={{textAlign:"justify"}}>
              The vision of {" "}
              <b style={{ color:"darkgoldenrod",fontSize:"20px" }}>"IOSC-BVP "</b>{" "}
              is to unite and empower budding technologists through innovation
              and collaboration. As organizers of a tech fair featuring
              Hackathon and Projecthon events, the society aims to provide a
              platform for diverse students to explore Intel's oneAPI toolkit,
              fostering creative problem-solving. 
            </p>

            {/* <br />
                        <p>
                            We believe that collaboration and inclusivity are the keys to
                            unlocking creativity and innovation, which is why we're committed to
                            providing a welcoming and supportive environment for all participants.
                            Whether you're an experienced developer or just starting out, whether
                            you come from a technical background or not, you'll find a place at Soft CodeHack 2.0.
                        </p>
                        <br />
                        <p>
                            During the event, you'll have access to cutting-edge tools, resources,
                            and mentors who will help you develop your ideas and bring them to
                            life. You'll work alongside other like-minded individuals, and have
                            the opportunity to learn new skills, build your network, and make a
                            positive impact on the world.
                        </p>
                        <br />
                        <p>
                            If you're passionate about technology, engineering, and
                            sustainability, and you want to be part of a collaborative and
                            inclusive community working towards a better world, then Soft CodeHack 2.0 is the event for you. Join us and let's hack our way to a brighter
                            future together!
                        </p> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
