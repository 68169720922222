import React from "react";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";

const NewTimeline = () => {
  return (
    <>
    <h1 className="common-title">Event Timelines</h1>
      <div className="check-more">
        <Link to="/timeline" className="checkprize-btn">
          Click to view Timelines
          <FaArrowRight className="icon" />
        </Link>
      </div>
    </>
  );
};

export default NewTimeline;
