import React from "react";
import DevfolioApplyButton from "./Devfoliobtn";

const Hero = () => {
  return (
    <section id="home">
      <div className="hero-section">
        <div className="hero">
          <h1 className="hero-title" style={{fontFamily:"-moz-initial"}}>TechGenesis</h1>
          <p className="hero-sub">
            Unite, Innovate, and Compete at TECH-FAIR!
          </p>
          <div className="hero-btn">
            <DevfolioApplyButton />
            {/* <a href="mailto:techgenesis@ioscbvp.tech" className="btn-link">
              Sponsor Us
            </a> */}
            <a
              href="https://drive.google.com/file/d/1kDnGaMtZDU_GmtottW5_9isk5TNTSatc/view?usp=share_link"
              target="_blank"
              className="btn-link"
              
            >
            Information Document
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
