import React from "react";
// import unStop from "./images/unstop1.jpg"
import aceAcademy from "./images/ACE ACADEMY x ACE ONLINE logo (3) (6).png";
// import guvi from "./images/guviLogo.png"
import polygon from "./images/Polygon_Logo-Colored.png";
import repelit from "./images/Replit-light-background.png";
import devfolio from "./images/Devfolio_Logo-Colored.png";

const spnsrs = () => {
  return (
    <>
      <section className="section" id="sponsors">
        <div className="sponsors-section">
          <h1 className="common-title">Sponsors</h1>
          <div className="sponsor-boxes">
            <div className="box">
              <h2 className="spnsr-title-1 spnsr-title">Gold Sponsor</h2>
              <div className="spnsr-grid">
                <div className="spnsr-img">
                  <a href="#">
                    <img src={polygon} alt="POLYGON LOGO" />
                  </a>
                </div>
                <div className="spnsr-img">
                  <a href="#">
                    <img src={devfolio} alt="POLYGON LOGO" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="box">
            <h2 className="spnsr-title-2 spnsr-title">Silver Sponsor</h2>
            <div className="spnsr-grid">
              <div className="spnsr-img">
                <a href="#">
                  <img src={repelit} alt="POLYGON LOGO" />
                </a>
              </div>
            </div>
            {/* <div className="box">
              <h2 className='spnsr-title'>Silver Sponsors</h2>
              <div className='spnsr-grid'>
                <div className="spnsr-img">
                  <img src="../Verbwire.png" alt="" />
                </div>
              </div>
            </div> */}
            <div className="box">
              <h2 className="spnsr-title-3 spnsr-title">Education Sponsor</h2>
              <div className="spnsr-grid">
                <div className="spnsr-img">
                  <a href="https://www.aceenggacademy.com/">
                    <img src={aceAcademy} alt="POLYGON LOGO" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default spnsrs;
