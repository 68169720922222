import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
// import FastfoodIcon from "@mui/icons-material/Fastfood";
import DataObjectIcon from '@mui/icons-material/DataObject';
import DeveloperBoardSharpIcon from '@mui/icons-material/DeveloperBoardSharp';
import GroupSharpIcon from '@mui/icons-material/GroupSharp';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import Typography from "@mui/material/Typography";
import CoPresentIcon from '@mui/icons-material/CoPresent';

const TimelineTG = () => {

  return (
    <>
      {/* <section className="section" id="timelines">
                <div className="timeline-section">
                    <h1 className="common-title">Event Timelines</h1>
                    <div className="main-timeline">
                        <div className="timeline">
                            <div className="timeline-content">
                                <div class="timeline-year" style={{ right: "-35px" }}>16 Aug 2023</div>
                                <h3 className="title">Registration starts</h3>
                                <p className="description">
                                    You can register by clicking on Apply with Devfolio button above.
                                </p>
                            </div>
                        </div>

                        <div class="timeline">
                            <div class="timeline-content">
                                <div class="timeline-year"></div>
                                <h3 class="title">Orientation session at 10:00 AM</h3>
                                <p class="description">
                                    Join our discord server for getting session link.
                                </p>
                            </div>
                        </div>
                        <div class="timeline">
                            <div class="timeline-content">
                                <div class="timeline-year" style={{ right: "-25px" }}>
                                </div>
                                <h3 class="title">Mentor session at 04:00 PM</h3>
                                <p class="description">
                                    Get Insight on How to win the Hackathon?
                                </p>
                            </div>
                        </div>
                        <div class="timeline">
                            <div class="timeline-content">
                                <div class="timeline-year">4 Oct 2023</div>
                                <h3 class="title">Registration end</h3>
                                <p class="description">
                                    Last date of event registration
                                </p>
                            </div>
                        </div>
                        <div class="timeline">
                            <div class="timeline-content">
                                <div class="timeline-year">6 Oct 2023</div>
                                <h3 class="title">Hackathon starts</h3>
                                <p class="description">
                                    You can start building your project
                                </p>
                            </div>
                        </div>
                        <div class="timeline">
                            <div class="timeline-content">
                                <div class="timeline-year"></div>
                                <h3 class="title">Mini-event: 1</h3>
                                <p class="description">
                                    Get familiar with Machine Learning
                                </p>
                            </div>
                        </div>
                        <div class="timeline">
                            <div class="timeline-content">
                                <div class="timeline-year" style={{ right: "-42px" }}>
                                </div>
                                <h3 class="title">Mini-event: 2</h3>
                                <p class="description">
                                    Get familiar with App Development
                                </p>
                            </div>
                        </div>
                        <div class="timeline">
                            <div class="timeline-content">
                                <div class="timeline-year" style={{ left: "-42px" }}>
                                </div>
                                <h3 class="title">Mini-event: 3</h3>
                                <p class="description">
                                    Get familiar with Cyber Security
                                </p>
                            </div>
                        </div>
                        <div class="timeline">
                            <div class="timeline-content">
                                <div class="timeline-year" style={{ right: "-25px" }}>
                                    8 Oct 2023</div>
                                <h3 class="title">Hackathon ends</h3>
                                <p class="description">
                                    Last day of Hackathon
                                </p>
                            </div>
                        </div>
                        <div class="timeline">
                            <div class="timeline-content">
                                <div class="timeline-year" style={{ left: "-42px" }}>
                                </div>
                                <h3 class="title">Result announcement</h3>
                                <p class="description">
                                    You can check for winners
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
      <h1 className="common-title" style={{marginTop:"15px",fontSize:"40px"}}>Event Timelines</h1>
      <h1 className="spnsr-title-1 spnsr-title" style={{ color: "yellow" }}>
         DEV.FUSION: The &lt;Hack&gt;athon
      </h1>
      <br />
      <Timeline position="alternate">
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            align="right"
            variant="body2"
            color="lightgreen"
          >
            <h2>ROUND 1:</h2>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot>
              <DataObjectIcon sx={{color:"green"}}/>
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2, color: "aqua" }}>
            <Typography variant="h6" component="span">
              “THE QUALIFYING ROUND”
            </Typography>
            <Typography sx={{textAlign:"justify"}}>
              The first round will be conducted online, where the participants
              will submit their profiles along with their resumes. Participants
              will be required to share their
            </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            align="right"
            variant="body2"
            color="lightgreen"
          >
            <h2>ROUND 2:</h2>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot>
            <DeveloperBoardSharpIcon sx={{color:"red"}}/>
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2, color: "aqua" }}>
            <Typography variant="h6" component="span">
              “DEVELOPER ASSEMBLE”
            </Typography>
            <Typography sx={{textAlign:"justify"}}>
              Candidates will get the opportunity to interact with other
              participants and then form teams among themselves. Each team must
              have 4 members, 1 from each domain.
            </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            align="right"
            variant="body2"
            color="lightgreen"
          >
            <h2>ROUND 3:</h2>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot>
            <GroupSharpIcon sx={{color:"blue"}}/>
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2, color: "aqua" }}>
            <Typography variant="h6" component="span">
              “MEET YOUR MENTOR”
            </Typography>
            <Typography sx={{textAlign:"justify"}}>
              The formed teams will take the initiative of approaching the
              mentors introduced by the organising team. Teams can approach as
              many mentors as they want, there will be no assignment of mentors.
              The teams are expected to further modify their projects taking
              suggestions from the mentors. Judging will be based on the factors
              such as practicality and implementation, impact and understanding
              of mentors. It will be a 6 hour long round commencing from 12PM to
              6PM. ELIMINATIONS will take place in this round
            </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            align="right"
            variant="body2"
            color="lightgreen"
          >
            <h2>ROUND 4:</h2>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot>
            <AutoFixHighIcon sx={{color:"blueviolet"}}/>
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2, color: "aqua" }}>
            <Typography variant="h6" component="span">
              “MENTOR MAGIC”
            </Typography>
            <Typography sx={{textAlign:"justify"}}>
              Round 4 will be an ELIMINATION round. Qualification of this round
              will depend on whether the mentors are satisfied with your work.
              The mentors will evaluate your projects on the basis of:{" "}
              <b style={{ color: "#018cff" }}> <br />• Potential of the project.</b>
              <b style={{ color: "#018cff" }}> <br />
                • Modifications done in given time
              </b>
              <b style={{ color: "#018cff" }}> <br />
                • Coming close to the end goal{" "}
              </b> <br />
              The judging of this round will take place at 7 A.M. of Day 2 of
              our TechFair. The top 5 teams will make it to the final round.
            </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            align="right"
            variant="body2"
            color="lightgreen"
          >
            <h2>ROUND 5:</h2>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot>
            <PresentToAllIcon sx={{color:"firebrick"}}/>
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2, color: "aqua" }}>
            <Typography variant="h6" component="span">
              “PRESENTATION ROUND”
            </Typography>
            <Typography sx={{textAlign:"justify"}}>
              This will be judged by our special judge panel, mentors and the
              audience. The final round will consist of the final teams making
              presentations fitting to their projects and showcasing them in
              front of the mentors. Think of this as your big pitch for the
              Sharks. The winner will be decided on the basis of the most
              relevant presentation with respect to the themes.
              <br />
              <b style={{ color: "#018cff" }}>
                NOTE: Final selection will be made on the basis of pitch
                presented with the help of a powerpoint presentation.
              </b>
            </Typography>
          </TimelineContent>
        </TimelineItem>
      </Timeline>

      <h2
        className="spnsr-title-1 spnsr-title"
        style={{ color: "yellow", marginTop: "50px" }}
      >
        PROJEXON: The Exhibition 
      </h2>
      <br />
      <Timeline position="alternate">
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            align="right"
            variant="body2"
            color="lightgreen"
          >
            <h2>ROUND 1:</h2>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot>
              <HowToRegIcon sx={{color:"lightseagreen"}}/>
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2, color: "aqua" }}>
            <Typography variant="h6" component="span">
              “ONLINE REGISTRATION”
            </Typography>
            <Typography sx={{textAlign:"justify"}}>
              Participants will be required to submit their project ideas online
              along with the relevant image of the project . Selected teams will
              be contacted to report to the college premises for subsequent
              rounds.
            </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            align="right"
            variant="body2"
            color="lightgreen"
          >
            <h2>ROUND 2:</h2>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot>
            <PresentToAllIcon sx={{color:"firebrick"}}/>
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2, color: "aqua" }}>
            <Typography variant="h6" component="span">
              “PRESENTATION TO THE MENTORS”
            </Typography>
            <Typography sx={{textAlign:"justify"}}>
              Participants will be expected to report to the college premises,
              where they would present their projects in front of the mentors.
              The mentors will then select and choose the contestants qualifying
              for the subsequent rounds and provide their input regarding the
              projects. This round will serve as an elimination round.
            </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            align="right"
            variant="body2"
            color="lightgreen"
          >
            <h2>ROUND 3:</h2>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot>
             <CoPresentIcon sx={{color:"hotpink"}}/>
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2, color: "aqua" }}>
            <Typography variant="h6" component="span">
              “PRESENTATION TO THE JUDGES”
            </Typography>
            <Typography sx={{textAlign:"justify"}}>
              Participants will be expected to make necessary changes as
              suggested by the mentors and will then finally present their
              projects along with the working model (if any) and a powerpoint
              presentation in front of our esteemed judges. The winner will be
              selected on the basis of the most relevant project with respect to
              the theme.
            </Typography>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </>
  );
};

export default TimelineTG;
